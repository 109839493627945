import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-notification-item-card',
    standalone: true,
    imports: [
        CommonModule,
    ],
    templateUrl: './notification-item-card.component.html',
    styleUrl: './notification-item-card.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationItemCardComponent { 
    @Input() notification: any;
    @Input() type: any;
    @Input() isRead: any;

    ngOnInit() {
        console.log(this.notification);
    }
}
